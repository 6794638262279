import React from 'react';
import { gtag } from '../../helpers/gtag.js';
import { Button } from '@chakra-ui/react';

const WebsiteButton = ({
  href,
  trackLocation,
  targetBlank,
  fitContent,
  ...rest
}) => {
  const handleClick = () => {
    gtag('event', 'Click', {
      event_category: 'Button',
      event_label: `${rest.children} (${trackLocation})`
    });

    if (href) {
      if (targetBlank) {
        window.open(href, '_blank');

        return;
      }

      document.location.href = href;
    }
  };

  return <Button onClick={href && handleClick} {...rest} />;
};

export default WebsiteButton;
